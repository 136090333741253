import { BlueMediaPaymentStatus } from '../order';
import { BlueMediaRegistrationStatus } from '../place';

export interface BlueMediaFormResponseOk {
  link: string;
  hash: string;
  messageId: string;
  formHash: string;
}

export interface BlueMediaIcnData {
  acceptorId: number;
  serviceId: number;
  serviceKey: string;
  hash: string;
  verificationLinks: {
    link: string;
    currency: string;
  }[];
  formHash: string;
  method: string;
}

export interface IcnResponse {
  resultStatus: 'OK' | 'ERROR';
  description: string;
}

export interface BlueMediaItnOrderPaymentTransaction {
  orderID: string[];
  remoteID: string[];
  amount: string[];
  currency: string[];
  gatewayID: string[];
  paymentDate: string[];
  paymentStatus: BlueMediaPaymentStatus[];
  paymentStatusDetails: string[];
}

export interface BlueMediaItnVerificationTransaction
  extends BlueMediaItnOrderPaymentTransaction {
  addressIP: string[];
  verificationStatus?: (
    | BlueMediaRegistrationStatus.PENDING
    | BlueMediaRegistrationStatus.NEGATIVE
    | BlueMediaRegistrationStatus.POSITIVE
  )[];
}

interface BlueMediaItnContentBase<TransactionType> {
  transactionList: {
    serviceID: string[];
    transactions: {
      transaction: TransactionType[];
    }[];
    hash: string[];
  };
}

export type BlueMediaVerificationItn =
  BlueMediaItnContentBase<BlueMediaItnVerificationTransaction>;

export type BlueMediaOrderPaymentItn =
  BlueMediaItnContentBase<BlueMediaItnOrderPaymentTransaction>;

export enum BlueMediaConfirmation {
  CONFIRMED = 'CONFIRMED',
  NOTCONFIRMED = 'NOTCONFIRMED',
}

export enum BlueMediaCardsActivity {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface BlueMediaCardStatusUpdateBody {
  serviceId: number;
  orderId: string;
  cardsStatus: BlueMediaCardsActivity;
  currencies?: string[] | null;
  hash: string;
}

export interface BlueMediaCardStatusUpdateResponse {
  serviceId: number;
  orderId: string;
  confirmation: BlueMediaConfirmation;
  hash: string;
}

export interface GetBlueMediaServiceFormQueryReq {
  useAcceptorOfPlaceId?: string | null;
}
