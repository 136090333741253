import {
    BlueMediaCompanyData,
    BlueMediaPaymentStatus,
    BusinessHours,
    BusinessHoursException,
    Currency,
    FileEntity,
    FileRes,
    I18nEntry,
    NightMenuHoursEntity,
    OrderEntity,
    OwnerStatus,
    PlaceLocationEntity,
    PlacePrivilegesEntity,
    PlaceSlotEntity,
    PlaceStatus,
    PlaceTag,
    PlaceType,
    PlanAssignmentEntity,
    PlanEntity,
    UserEntity,
} from 'types';
import { PlacePlanDiscountType } from './place-plan-discount-type';

export enum PlaceReservationStatus {
    pause = 'pause',
    resume = 'resume',
}

export interface PlaceEntity {
    name: I18nEntry;
    id: string;
    description: I18nEntry;
    type: PlaceType;
    nip: string | null;
    landlineNumber: string;
    mobileNumber: string;
    mail: string;
    averageMealPreparationTime: number;
    averageReservationTime: number;
    owner: UserEntity;
    planAssignments?: PlanAssignmentEntity[];
    businessHours?: BusinessHours[];
    businessHoursExceptions?: BusinessHoursException[];
    nightMenuHours: NightMenuHoursEntity[];
    locations: PlaceLocationEntity[];
    avatar: FileEntity;
    thumbnail: FileEntity;
    pageUrl: string | null;
    privileges: PlacePrivilegesEntity[];
    contactPostalCode: string;
    contactCityName: string;
    contactStreetName: string;
    contactStreetIndex: string;
    orders: OrderEntity[];
    slots: PlaceSlotEntity[];
    removedAt: Date | null;
    rejectedAt: Date | null;
    currency: Currency;
    status: PlaceStatus;
    isPromoted: boolean;
    bluemediaFormHash: string | null;
    bluemediaVerificationPaymentStatus: BlueMediaPaymentStatus | null;
    bluemediaVerificationPaymentLink: string | null;
    bluemediaRegistrationStatus: BlueMediaRegistrationStatus | null;
    bluemediaServiceId: number | null;
    bluemediaAcceptorId: number | null;
    bluemediaPaymentToken: string | null;
    bluemediaPaymentTokenValidityEndsAt: Date;
    bluemediaCardPaymentIsActive: boolean;
    bluemediaSharedKey: string | null;
    bluemediaAMLData: BlueMediaCompanyData | null;
    bluemediaAMLDataUpdatedAt: Date | null;
    salesRules: FileEntity;
    dataProcessingRules: FileEntity;
    tags: PlaceTag[];
    createdAt: Date;
    planDiscount: number;
    planDiscountType: PlacePlanDiscountType;
}

export interface ListedPlaceBrief {
    id: string;
    latitude: number;
    longitude: number;
    name: string;
    descriptionBrief: string;
    avatarSrc: string;
    type: PlaceType;
    thumbnailSrc: string;
    promoted: boolean;
    postalCode: string;
    cityName: string;
    streetName: string;
    streetIndex: string;
    status: PlaceStatus;
    allowsTableReservations: boolean;
    isPromoted: boolean;
    tags: PlaceTag[];
}

export interface BusinessHoursAsDaysRes {
    monday: BusinessHours;
    tuesday: BusinessHours;
    wednesday: BusinessHours;
    thursday: BusinessHours;
    friday: BusinessHours;
    saturday: BusinessHours;
    sunday: BusinessHours;
}

export interface ListedPlacePanelData {
    id: string;
    name: I18nEntry;
    nip: string;
    type: PlaceType;
    currentPlanName: string | null;
    status: PlaceStatus;
    planDiscount: number;
    planDiscountType: PlacePlanDiscountType;
}

export interface PlacePanelData {
    pageUrl: string | null;
    id: string;
    contactCity: string;
    description: I18nEntry;
    isPromoted: boolean;
    averageMealPreparationTime: number | null;
    avatar: FileRes;
    backgroundImage: FileRes;
    contactMail: string;
    contactPostalCode: string;
    contactStreet: string;
    contactStreetIndex: string;
    owner: {
        id: string;
        name: string;
        surname: string;
        nip: string;
    };
    currentPlan: PlanEntity | null;
    type: PlaceType;
    localizationCity: string;
    localizationStreet: string;
    localizationStreetIndex: string;
    localizationPostalCode: string;
    lng: number;
    lat: number;
    mobileNumber: string;
    landlineNumber: string;
    nightMenuHours: BusinessHours[];
    businessHours: BusinessHours[];
    name: I18nEntry;
    nip: string;
    status: PlaceStatus;
    blueMediaStatus: string | null;
    currency: Currency;
    salesRulesSrc: string;
    salesRulesFileId: string;
    dataProcessingRulesSrc: string;
    dataProcessingRulesFileId: string;
    reservationStatus: PlaceReservationStatus;
    tags: PlaceTag[];
    planDiscount: number;
    planDiscountType: PlacePlanDiscountType;
}

export interface PlaceCardData {
    name: I18nEntry;
    nip: string;
    contactPostalCode: string;
    contactCityName: string;
    contactStreetName: string;
    contactStreetIndex: string;
    mail: string;
    landlineNumber: string;
    mobileNumber: string;
    avatarId: string | null;
    localizationCityName: string | null;
    localizationStreetName: string | null;
    localizationStreetIndex: string | null;
    localizationPostalCode: string | null;
    dataProcessingRulesSrc: string | null;
    salesRulesSrc: string | null;
    lng: number | null;
    lat: number | null;
    currency: Currency;
}

export interface PlaceSearchingListPosition {
    id: string;
    name: string;
    latitude: number;
    longitude: number;
    descriptionBrief: string | null;
    promoted: boolean;
    avatarSrc: string | null;
    tags: PlaceTag[];
}

export interface PlaceFormLocationBase {
    localizationPostalCode: string;
    localizationCity: string;
    localizationStreet: string;
    localizationStreetIndex: string;
    lat: number;
    lng: number;
}

export interface CreatePlaceEntityReq extends PlaceFormLocationBase {
    name: I18nEntry;
    nip: string;
    type: PlaceType;
    contactCity: string;
    contactPostalCode: string;
    contactStreet: string;
    contactStreetIndex: string;
    landlineNumber?: string | null;
    mobileNumber: string;
    contactMail: string;
    planId: string;
    ownerId?: string | null;
    description: I18nEntry;
    averageMealPreparationTime: number;
    avatarId: string;
    backgroundImageId: string;
    pageUrl?: string | null;
    currency: Currency;
    exceptions?: BusinessHoursException[] | null;
    salesRulesFileId: string;
    dataProcessingRulesFileId: string;
    tags: PlaceTag[];
    planEndsAt?: Date;
}

export interface EditPlaceReq extends PlaceFormLocationBase {
    name: I18nEntry;
    description: I18nEntry;
    averageMealPreparationTime: number;
    avatarId: string | null;
    backgroundImageId: string | null;
    nip: string;
    contactCity: string;
    contactPostalCode: string;
    contactStreet: string;
    contactStreetIndex: string;
    landlineNumber?: string | null;
    mobileNumber: string;
    contactMail: string;
    currency: Currency;
    pageUrl?: string | null;
    salesRulesFileId: string;
    dataProcessingRulesFileId: string;
    tags: PlaceTag[];
    businessHours?: BusinessHours[] | null;
    nightMenuHours?: BusinessHours[] | null;
}

export enum MissingPlaceRequirements {
    ACTIVE_OWNER = 'ACTIVE_OWNER',
    ACTIVE_PLACE = 'ACTIVE_PLACE',
    ACTIVE_PLAN = 'ACTIVE_PLAN',
    ACTIVE_PAYMENTS = 'ACTIVE_PAYMENTS',
}

export interface PlaceOnSelectList {
    id: string;
    nip: string;
    blueMediaStatus: BlueMediaRegistrationStatus | null;
    missingRequirements: MissingPlaceRequirements[];
    name: I18nEntry;
    avatarSrc: string;
    status: PlaceStatus;
    currentPlan: PlanAssignmentEntity;
}

export enum BlueMediaRegistrationStatus {
    REGISTERED = 'REGISTERED',
    PENDING = 'PENDING',
    POSITIVE = 'POSITIVE',
    NEGATIVE = 'NEGATIVE',
    INTEGRATOR_UPDATE_CANCELED = 'INTEGRATOR_UPDATE_CANCELED',
}

export interface ChangePlaceStatusReq {
    status: PlaceStatus;
    endsAt?: Date;
}

export interface PlaceOrderAcceptanceRequirementsStatus {
    hasAcceptedOwner: boolean;
    hasActivePlan: boolean;
    hasCurrentLocation: boolean;
    isAccepted: boolean;
    isAcceptedByBlueMedia: boolean;
}

export interface PartnersPanelData {
    name: I18nEntry;
    nip: string;
    id: string;
}

export interface GivePlacePlanDiscountReq {
    value: number;
    type: PlacePlanDiscountType;
}

export interface SetCustomPlanEndDateReq {
    endsAt?: Date;
}

export interface PlaceByOwner extends Pick<PlaceEntity, 'id' | 'name' | 'status' | 'nip' | 'bluemediaRegistrationStatus'> {
    owner: {
        id: string;
        ownerStatus: OwnerStatus;
    };
    avatar: {
        networkPath: string;
    };
    assignment: {
        id: string;
        startAt: Date;
        endsAt: Date | null;
        confirmedAt: Date | null;
    }
}

export interface PlaceByEmployee extends Pick<PlaceEntity, 'id' | 'name' | 'status' | 'nip' | 'bluemediaRegistrationStatus'> {
    employee: {
        id: string;
    };
    avatar: {
        networkPath: string;
    };
    assignment: {
        id: string;
        startAt: Date;
        endsAt: Date | null;
        confirmedAt: Date | null;
    }
}