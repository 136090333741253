import styled from '@emotion/styled';
import { Container } from '../../components/Layouts/Container';
import { Logo } from '../../components/Common/Logo';

const StyledPrivacyContainer = styled.div`
max-width: 1000px;
margin: 0 auto;
h1, h2, h3 {
    font-weight: 700;
    margin: 20px 0;
}
p {
    margin: 10px 0;
    line-height: 1.5;
}

ul {
    margin: 10px 0;
    padding: 0;
    padding-left: 20px;
    list-style-type: none;
}

li {
    margin-bottom: 5px;
}

a {
    font-weight: 500;
    text-decoration: underline;
}
`;

const StyledLogoContainer = styled.div`
text-align: center;
padding: 30px;
`;

const Privacy = () => (
    <Container>
        <StyledPrivacyContainer>
            <StyledLogoContainer>
                <Logo />
            </StyledLogoContainer>
            <p>
                Polityka prywatności obejmująca kwestie przetwarzania danych osobowych RODO w ramach
                korzystania przez nich z Aplikacji Eatly
            </p>
            <h1>POLITYKA PRYWATNOŚCI APLIKACJI EATLY</h1>
            <p>Prywatność i ochrona danych osobowych są naszymi priorytetami.</p>
            <p>
                Polityka prywatności Quality App Studio Sp. z o.o. z siedzibą w Krakowie (dalej: „my” oraz „”)
                została przygotowana, aby wyjaśnić, w jaki sposób przetwarzamy Twoje dane osobowe w związku z
                korzystaniem przez Ciebie z aplikacji Eatly – w tym w jaki sposób gromadzimy, wykorzystujemy,
                przechowujemy, udostępniamy, przesyłamy, przekazujemy, usuwamy Twoje Dane lub w inny sposób
                przetwarzamy je (operacje te są zwane dalej łącznie „przetwarzaniem” danych osobowych).
            </p>
            <p>
                Terminy takie jak przetwarzanie oraz dane osobowe należy rozumieć zgodnie z ich definicjami
                określonymi w art. 4 RODO (Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia
                27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych
                osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE
                (ogólne rozporządzenie o ochronie danych), dalej: „RODO”). Dane osobowe oznaczają wszelkie
                dane dotyczące zidentyfikowanej lub możliwej do zidentyfikowania osoby fizycznej lub osoby,
                która może zostać zidentyfikowana za pomocą uzasadnionych środków.
            </p>
            <p>
                W dokumencie Polityki prywatności Quality App studio Sp. z o.o. (dalej: „Polityka”) zawarliśmy
                informację o środkach technicznych i organizacyjnych, które zostały przez nas zastosowane w
                celu zapewnienia, że Twoje dane osobowe są z nami bezpieczne, a także informację o tym, w jaki
                sposób możecie się z nami skontaktować w przypadku jakichkolwiek pytań związanych z ochroną
                danych.
            </p>
            <p>Spis treści znajdujących się w niniejszej Polityce:</p>
            <h2>Spis treści</h2>
            <ul>
                <li>
                    I. Czym jest Polityka prywatności
                </li>
                <li>
                    II. Jak gromadzimy i przetwarzamy dane
                    osobowe
                </li>
                <li>
                    III. Szczegółowe informacje dotyczące przetwarzania danych osobowych użytkowników Aplikacji
                    (konsumentów) w związku z zamawianiem posiłków
                </li>
                <li>
                    IV. Szczegółowe informacje dotyczące przetwarzania danych osobowych restauratorów o w
                    związku z założeniem konta w Aplikacji oraz pracowników restauracji o przetwarzaniu danych
                    osobowych w ramach subkont Aplikacji
                </li>
            </ul>

            <h2>I. Czym jest Polityka prywatności</h2>
            <p>
                Niniejsza Polityka dotyczy przetwarzania przez Quality App Studio sp. z o.o. danych osobowych
                użytkowników Aplikacji Eatly (dalej: „Aplikacja”), gromadzonych przez nas bezpośrednio lub
                pośrednio.
            </p>

            <p>
                Dane osobowe, o których mowa, dotyczą wszystkich użytkowników korzystających z Aplikacji, w
                tym m.in. pracowników naszych klientów, ich klientów, partnerów biznesowych lub konsumentów
                korzystających z ich usług lub produktów.
            </p>
            <h2>II. Jak gromadzimy i przetwarzamy dane osobowe</h2>
            <p>
                Będziemy przestrzegać obowiązujących przepisów prawa ochrony danych osobowych. W związku z tym
                zapewniamy, że dane osobowe będą gromadzone i przetwarzane zgodnie z RODO oraz innymi
                przepisami dotyczącymi ochrony danych osobowych.
            </p>
            <h3>1. Zasady przetwarzania danych osobowych</h3>
            <p>W szczególności przetwarzamy dane osobowe:</p>
            <ul>
                <li>
                    a) zgodnie z prawem, rzetelnie i w sposób przejrzysty dla osoby, której dane dotyczą
                    (`&quot;zgodność z prawem, rzetelność i przejrzystość&quot;);
                </li>
                <li>
                    b) zbierane w konkretnych, wyraźnych i prawnie uzasadnionych celach i nieprzetwarzane dalej
                    w sposób niezgodny z tymi celami (&quot;ograniczenie celu&quot;);
                </li>
                <li>
                    c) adekwatne, stosowne oraz ograniczone do tego, co niezbędne do celów, w których są
                    przetwarzane (&quot;minimalizacja danych&quot;);
                </li>
                <li>
                    d) prawidłowe i w razie potrzeby uaktualniane. Podejmujemy wszelkie rozsądne działania, aby
                    dane osobowe, które są nieprawidłowe w świetle celów ich przetwarzania, zostały niezwłocznie
                    usunięte lub sprostowane (&quot;prawidłowość&quot;);
                </li>
                <li>
                    e) przechowywane w formie umożliwiającej identyfikację osoby, której dane dotyczą, przez
                    okres nie dłuższy, niż jest to niezbędne do celów, w których dane te są przetwarzane
                    (&quot;ograniczenie przechowywania&quot;);
                </li>
                <li>
                    f) przetwarzane w sposób zapewniający odpowiednie bezpieczeństwo danych osobowych, w tym
                    ochronę przed niedozwolonym lub niezgodnym z prawem przetwarzaniem oraz przypadkową utratą,
                    zniszczeniem lub uszkodzeniem, za pomocą odpowiednich środków technicznych lub
                    organizacyjnych (&quot;integralność i poufność&quot;).
                </li>
            </ul>

            <h3>2. Bezpieczeństwo przetwarzania danych osobowych</h3>
            <p>
                Uwzględniając takie kryteria jak stan wiedzy technicznej, charakter, zakres, kontekst i cele
                przetwarzania oraz ryzyko naruszenia praw lub wolności osób fizycznych o różnym
                prawdopodobieństwie i wadze, wdrożyliśmy odpowiednie środki techniczne i organizacyjne:
            </p>
            <ul>
                <li>
                    • aby przetwarzanie Twoich danych osobowych odbywało się zgodnie z przepisami prawa ochrony
                    danych osobowych;
                </li>
                <li>
                    • aby zapewnić Twoim danym stopień bezpieczeństwa odpowiadający ryzyku naruszenia praw lub
                    wolności osób fizycznych.
                </li>
            </ul>
            <p>Środki te są w razie potrzeby poddawane przeglądom i uaktualniane.</p>

            <h2>
                III. Szczegółowe informacje dotyczące przetwarzania danych osobowych użytkowników Aplikacji
                (konsumentów) w związku z zamawianiem posiłków
            </h2>
            <h3>1. Administrator danych osobowych</h3>
            <p>
                Administratorem Twoich danych osobowych będzie Quality App Studio Sp. z o.o. z siedzibą w
                Krakowie. Możesz się z nami skontaktować w następujący sposób:
            </p>
            <ul>
                <li>- listownie na adres:</li>
                <li>- przez e-mail: dane@qualityappstudio.pl</li>
            </ul>

            <div className="privacy-block">
                Wskazujemy, że niniejszy dokument dotyczy wyłącznie przetwarzania danych osobowych związanych
                z Twoim korzystaniem z Aplikacji w zakresie jej ogólnych funkcjonalności, tj. zarejestrowania
                się do niej oraz utworzenia i obsługi Twojego konta jako użytkownika Aplikacji (konsumenta).
                Natomiast w zakresie szczególnych funkcjonalności Aplikacji jak jakiekolwiek zewnętrzne
                programy lojalnościowe, konkursy lub inne aktywności, w których uczestniczysz, to podmioty je
                organizujące są (odrębnymi od nas) administratorami Twoich danych osobowych. W przypadku więc
                pytań dotyczących przetwarzania danych osobowych w ramach szczególnych funkcjonalności
                Aplikacji, należy kontaktować się z odpowiednim podmiotem będącym organizatorem programu
                lojalnościowego, konkursu lub innej aktywności (dane dotyczące tego podmiotu zapewne
                znajdziesz w korespondencji dotyczącej Twojego uczestnictwa w takim programie lub konkursie).
            </div>
            <h3>2. Cele przetwarzania Twoich danych osobowych oraz podstawa prawna przetwarzania</h3>
            <p>Będziemy przetwarzać Twoje dane osobowe, aby:</p>
            <ul>
                <li>
                    −{' '}
                    <strong>
                        podjąć działania związane z obsługą Twojej rejestracji do Aplikacji oraz świadczeniem
                        usług drogą elektroniczną w zakresie utworzenia i obsługi Twojego konta jako użytkownika
                        Aplikacji
                    </strong>
                    . Podstawą prawną przetwarzania Twoich danych jest niezbędność tych działań do wykonania
                    umowy z nami, której jesteś stroną, a także podjęcie działań na Twoje żądanie przed
                    zawarciem tej umowy;
                </li>
                <li>
                    −{' '}
                    <strong>
                        umożliwić Ci dokonywanie rezerwacji stolika oraz zamówienia posiłku w wybranym przez
                        Ciebie miejscu (lokalu)
                    </strong>
                    . Podstawą prawną przetwarzania Twoich danych jest niezbędność tych działań do wykonania
                    umowy z nami, której jesteś stroną;
                </li>
                <li>
                    −{' '}
                    <strong>
                        bronić się przed ewentualnymi roszczeniami lub dochodzić ewentualnych roszczeń związanych
                        z Twoim korzystaniem z Aplikacji
                    </strong>
                    . Podstawą prawną przetwarzania Twoich danych jest nasz prawnie uzasadniony interes
                    polegający na możliwości dochodzenia lub obrony przed roszczeniami;
                </li>
                <li>
                    −{' '}
                    <strong>
                        prowadzić marketing bezpośredni naszych produktów i usług oraz produktów i usług podmiotów
                        trzecich
                    </strong>
                    . Przez podmioty trzecie rozumiemy w szczególności restauracje współpracujące z Quality App
                    Studio Sp. z o.o., których lista znajduje się tutaj.
                </li>
            </ul>

            <p>
                Będziemy wysyłać Ci materiały marketingowe i oferty lub dzwonić do Ciebie w tej sprawie – w
                zależności od sposobu kontaktu, który wybrałeś. Ponadto będziemy w oparciu o profilowanie
                dopasowywać treść informacji marketingowych na podstawie zgromadzonych, dotyczących Ciebie
                danych, w tym danych o których piszemy w pkt 3 poniżej oraz danych pochodzących z plików
                cookies. Działania marketingowe będą obejmowały w szczególności: wysyłanie dedykowanego
                newslettera, powiadomień o ofertach, remarketing (wyświetlanie reklam z naszą ofertą tym
                użytkownikom, którzy już odwiedzili naszą stronę) oraz marketing behawioralny (nakierowanie
                uwagi użytkownika na określone treści na podstawie jego wcześniejszych zachowań).
            </p>
            <p>
                Podstawą prawną przetwarzania Twoich danych dla celów marketingu naszych produktów i usług
                jest nasz prawnie uzasadniony interes polegający na możliwości kierowania informacji
                marketingowych do użytkowników naszej Aplikacji.
            </p>
            <p>
                W zakresie informacji marketingowych dotyczących produktów i usług współpracujących z nami
                restauracjami, będziemy przetwarzać Twoje dane osobowe na podstawie Twojej zgody, którą możesz
                wycofać w dowolnym momencie.
            </p>
            <h3>3. Kategorie przetwarzanych danych osobowych i źródło pochodzenia danych osobowych</h3>
            <p>
                Będziemy przetwarzać kategorie Twoich danych osobowych, które przekazałeś nam poprzez
                uzupełnienie formularza rejestracji do Aplikacji.
            </p>
            <p>
                Rejestracja, a także późniejsze logowanie do Aplikacji jest także możliwe za pośrednictwem
                platform i usług podmiotów trzecich. Decyzję o tej drodze rejestracji lub logowania
                podejmujesz Ty. W przypadku rejestracji lub logowania się do naszych usług przy użyciu danych
                uwierzytelniających podmiotu zewnętrznego (np. Facebook, Google, Apple) importujemy od niego
                informacje, aby pomóc w utworzeniu dla Ciebie konta w Aplikacji.
            </p>
            <p>
                Platforma wykorzystuje pliki „cookie&quot; – szczegółowa informacja ich dotycząca znajduje się w
                polityce plików cookie.
            </p>
            <h3>4. Okres przechowywania Twoich danych osobowych</h3>
            <p>
                Twoje dane osobowe będą przechowywane do czasu wypełnienia prawnie uzasadnionych interesów
                administratora stanowiących podstawę tego przetwarzania:
            </p>
            <ul>
                <li>
                    − do czasu, aż usuniesz to konto, lub my zakończymy świadczenie usług w ramach Aplikacji (w
                    zależności od tego, który z terminów będzie wcześniejszy).
                </li>
                <li>
                    − a po tym czasie także dla realizacji interesów w szczególności związanych z przedawnieniem
                    ewentualnych roszczeń lub do momentu złożenia przez Ciebie uzasadnionego sprzeciwu wobec
                    przetwarzania Twoich danych osobowych przez Quality App Studio sp. z o. o. (w zależności od
                    tego, który z terminów będzie wcześniejszy).
                </li>
            </ul>
            <p>
                W zakresie działalności marketingowej będziemy przechowywać Twoje dane do czasu wycofania
                przez Ciebie zgody na przetwarzanie danych osobowych dla tego celu.
            </p>

            <h3>5. Odbiorcy Twoich danych osobowych</h3>
            <p>Będziemy przekazywać Twoje dane osobowe:</p>
            <ul>
                <li>
                    − Podmiotom przetwarzającym dane osobowe w naszym imieniu w tym dostawcom usług IT, usług
                    marketingowych (w tym spoza Europejskiego Obszaru Gospodarczego), dostawcom hostingu oraz
                    usług w zakresie organizacji wydarzeń. Ponadto także operatorowi Aplikacji i operatorom
                    wysyłki wiadomości e-mail i SMS. Takie podmioty przetwarzają dane na podstawie umowy z nami
                    i tylko zgodnie z naszymi poleceniami;
                </li>
                <li>− lokalowi, w którym dokonujesz rezerwacji stolika przy pomocy Aplikacji.</li>
            </ul>

            <h3>6. Przekazywanie danych poza Europejski Obszar Gospodarczy</h3>
            <p>
                Twoje dane osobowe będą przekazywane do Stanów Zjednoczonych do zewnętrznych dostawców usług
                IT oraz dostawców usług marketingowych. Na obecną chwilę Stany Zjednoczone nie gwarantują
                odpowiedniego poziomu ochrony danych. Organy państwowe mogą mieć dostęp do tych danych ze
                względu na przepisy prawa zezwalające na inwigilację.
            </p>
            <p>
                Niektórzy z dostawców usług, z których korzystamy, przyjęli standardowe klauzule ochrony
                danych i pomimo istnienia przepisów prawa, o których mowa powyżej, starają się zapewnić
                możliwie jak najwyższy poziom ochrony danych.
            </p>
            <h3>7. Twoje prawa związane z przetwarzaniem danych osobowych</h3>
            <p>Masz następujące prawa związane z przetwarzaniem danych osobowych:</p>
            <ul>
                <li>a. prawo do wycofania zgody,</li>
                <li>
                    b. prawo wyrażenia sprzeciwu wobec przetwarzania Twoich danych ze względu na Twoją
                    szczególną sytuację – w przypadkach, kiedy przetwarzamy Twoje dane na podstawie naszego
                    prawnie uzasadnionego interesu,
                </li>
                <li>c. prawo dostępu do Twoich danych osobowych,</li>
                <li>d. prawo żądania sprostowania Twoich danych osobowych,</li>
                <li>e. prawo żądania usunięcia Twoich danych osobowych,</li>
                <li>f. prawo żądania ograniczenia przetwarzania Twoich danych osobowych,</li>
                <li>
                    g. prawo do przenoszenia Twoich danych osobowych, tj. prawo otrzymania od nas Twoich danych
                    osobowych, w ustrukturyzowanym, powszechnie używanym formacie informatycznym nadającym się
                    do odczytu maszynowego. Możesz przesłać te dane innemu administratorowi danych lub zażądać,
                    abyśmy przesłali Twoje dane do innego administratora. Jednakże zrobimy to tylko jeśli takie
                    przesłanie jest technicznie możliwe.
                </li>
            </ul>
            <p>
                Aby skorzystać z powyższych praw, skontaktuj się z nami (dane kontaktowe w punkcie 1 powyżej).
            </p>
            <p>
                <u>Prawo wycofania zgody</u>
            </p>
            <p>
                W zakresie, w jakim Twoje dane są przetwarzane na podstawie zgody, masz prawo wycofania zgody
                na przetwarzanie danych w dowolnym momencie. Wycofanie zgody nie ma wpływu na zgodność z
                prawem przetwarzania, którego dokonano na podstawie Twojej zgody przed jej wycofaniem.
            </p>
            <p>
                Zgodę możesz wycofać poprzez wysłanie oświadczenia na adres email dane@qualityappstudio.pl
            </p>

            <p>
                <u>Prawo wyrażenia sprzeciwuy</u>
            </p>
            <p>
                W związku z tym, że Twoje dane są przetwarzane na podstawie naszego prawnie uzasadnionego
                interesu (art. 6 ust. 1 lit. f) RODO) – w celu marketingu bezpośredniego masz prawo zgłoszenia
                sprzeciwu wobec przetwarzania Twoich danych osobowych przez Quality App Studio Sp. z o.o. w
                dowolnym momencie. Masz także prawo zgłoszenia sprzeciwu wobec przetwarzania Twoich danych
                osobowych przez Quality App Studio Sp. z o.o. ze względu na Twoją szczególną sytuację.
            </p>
            <p>
                Sprzeciw możesz zgłosić poprzez wysłanie oświadczenia na adres email dane@qualityappstudio.pl
            </p>
            <p>
                <u>Prawo wniesienia skargi do organu</u>
            </p>
            <p>
                Przysługuje Ci także prawo wniesienia skargi do organu nadzorczego zajmującego się ochroną
                danych osobowych, tj. Prezesa Urzędu Ochrony Danych Osobowych.
            </p>
            <h3>8. Wymóg podania danych</h3>
            <p>
                Podanie danych przez Ciebie danych osobowych jest niezbędne, aby zarejestrować się do
                Aplikacji i móc z niej korzystać.
            </p>
            <h2>
                IV. Szczegółowe informacje dotyczące przetwarzania danych osobowych restauratorów w związku z
                założeniem konta w Aplikacji oraz pracowników restauracji w ramach subkont Aplikacji
            </h2>
            <h3>1. Administrator danych osobowych</h3>
            <p>
                Administratorem Twoich danych osobowych będzie Quality App Studio Sp. z o.o. z siedzibą w
                Krakowie. Możesz się z nami skontaktować w następujący sposób:
            </p>
            <ul>
                <li>- listownie na adres: ul. Jana Sobieskiego 7/1, 31-136 Kraków </li>
                <li>
                    - przez e-mail: <a href="mailto:dane@qualityappstudio.pl">dane@qualityappstudio.pl</a>
                </li>
            </ul>
            <h3>2. Cele przetwarzania Twoich danych osobowych oraz podstawa prawna przetwarzania</h3>
            <p>Będziemy przetwarzać Twoje dane osobowe, aby:</p>
            <ul>
                <li>
                    −{' '}
                    <strong>
                        zweryfikować, czy jesteś uprawniony do tego, aby zarejestrować Cię jako użytkownika
                        Aplikacji
                    </strong>
                    . Podstawą prawną przetwarzania Twoich danych jest nasz prawnie uzasadniony interes
                    polegający na konieczności weryfikacji osób zgłaszających się do Aplikacji z ramienia
                    podmiotu - innego użytkownika lub naszego klienta, którzy korzystają z Aplikacji, a którzy
                    zgłosili, że jesteś uprawniony do korzystania z Aplikacji. W tym zakresie weryfikujemy, czy
                    faktycznie jesteś osobą zgłoszoną przez ten podmiot, względem którego możesz być w
                    szczególności pracownikiem.
                </li>
                <li>
                    −{' '}
                    <strong>
                        podjąć działania związane z obsługą Twojej rejestracji do Aplikacji oraz świadczeniem
                        usług drogą elektroniczną w zakresie utworzenia i obsługi Twojego konta jako użytkownika
                        Aplikacji
                    </strong>
                    . Podstawą prawną przetwarzania Twoich danych jest niezbędność tych działań do wykonania
                    umowy z nami, której jesteś stroną, a także podjęcie działań na Twoje żądanie przed
                    zawarciem tej umowy;
                </li>
                <li>
                    −{' '}
                    <strong>
                        bronić się przed ewentualnymi roszczeniami lub dochodzić ewentualnych roszczeń związanych
                        z Twoim korzystaniem z Aplikacji
                    </strong>
                    . Podstawą prawną przetwarzania Twoich danych jest nasz prawnie uzasadniony interes
                    polegający na możliwości dochodzenia lub obrony przed roszczeniami;
                </li>
                <li>
                    −{' '}
                    <strong>
                        prowadzić marketing bezpośredni naszych produktów i usług oraz produktów i usług podmiotów
                        trzecich
                    </strong>
                    . Przez podmioty trzecie rozumiemy w szczególności restauracje współpracujące z Quality App Studio Sp. z o.o., których lista znajduje się tutaj.
                </li>
            </ul>
            <p>
                Będziemy wysyłać Ci materiały marketingowe i oferty lub dzwonić do Ciebie w tej sprawie – w
                zależności od sposobu kontaktu, który wybrałeś. Ponadto będziemy w oparciu o profilowanie
                dopasowywać treść informacji marketingowych na podstawie zgromadzonych, dotyczących Ciebie
                danych, w tym danych o których piszemy w pkt 3 poniżej oraz danych pochodzących z plików
                cookies. Działania marketingowe będą obejmowały w szczególności wysyłanie dedykowanego
                newslettera, powiadomień o ofertach, remarketing (wyświetlanie reklam z naszą ofertą tym
                użytkownikom, którzy już odwiedzili naszą stronę) oraz marketing behawioralny (nakierowanie
                uwagi użytkownika na określone treści na podstawie jego wcześniejszych zachowań).
            </p>
            <p>
                Podstawą prawną przetwarzania Twoich danych dla celów marketingu naszych produktów i usług
                jest nasz prawnie uzasadniony interes polegający na możliwości kierowania informacji
                marketingowych do użytkowników naszej Aplikacji.
            </p>
            <p>
                W zakresie informacji marketingowych dotyczących produktów i usług podmiotów trzecich będziemy
                przetwarzać Twoje dane osobowe na podstawie Twojej zgody, którą możesz wycofać w dowolnym
                momencie.
            </p>
            <h3>3. Kategorie przetwarzanych danych osobowych i źródło pochodzenia danych osobowych</h3>
            <p>
                Będziemy przetwarzać kategorie Twoich danych osobowych, które przekazałeś nam poprzez
                uzupełnienie formularza rejestracji do Aplikacji, jak i przekazane nam przez podmiot – innego
                użytkownika lub naszego klienta, którzy korzystają z Aplikacji, a którzy zgłosili, że jesteś
                uprawniony do korzystania z Aplikacji.
            </p>
            <p>
                W szczególności będą to takie dane jak Twoje imię, nazwisko, adres e-mail, numer telefonu,
                nadany Ci przez podmiot zgłaszający Twoje uprawnienie do zarejestrowania się do Aplikacji
                numer lub inna unikalne dla każdego użytkownika dan. Niektóre dane osobowe mogą być przez
                Ciebie zamieszczone na Aplikacji, jeśli podejmiesz stosowne działania (np. dodasz do Twojego
                konta Twoje zdjęcie).
            </p>
            <p>
                Platforma wykorzystuje pliki „cookie&quot; – szczegółowa informacja ich dotycząca znajduje się w
                polityce plików cookie.
            </p>
            <h3>4. Okres przechowywania Twoich danych osobowych</h3>
            <p>
                Twoje dane osobowe będą przechowywane do czasu wypełnienia prawnie uzasadnionych interesów
                administratora stanowiących podstawę tego przetwarzania:
            </p>
            <ul>
                <li>
                    − przez czas weryfikacji, czy jesteś uprawniony do tego, aby zarejestrować Cię jako
                    użytkownika Aplikacji, a jeśli staniesz się użytkownikiem Aplikacji – także na czas Twojego
                    korzystania z Aplikacji;
                </li>
                <li>
                    − a po tym czasie także dla realizacji interesów w szczególności związanych z przedawnieniem
                    ewentualnych roszczeń lub do momentu złożenia przez Ciebie uzasadnionego sprzeciwu wobec
                    przetwarzania Twoich danych osobowych przez Quality App Studio Sp. z o.o. w zależności od
                    tego, który z terminów będzie wcześniejszy).
                </li>
            </ul>
            <p>
                W zakresie obsługi Twojego konta jako użytkownika Aplikacji będziemy przechowywać Twoje dane
                do czasu, aż usuniesz to konto, lub my zakończymy świadczenie usług w ramach Aplikacji (w
                zależności od tego, który z terminów będzie wcześniejszy).
            </p>
            <p>
                W zakresie działalności marketingowej będziemy przechowywać Twoje dane do czasu wycofania
                przez Ciebie zgody na przetwarzanie danych osobowych dla tego celu.
            </p>
            <h3>5. Odbiorcy Twoich danych osobowych</h3>
            <p>Będziemy przekazywać Twoje dane osobowe:</p>
            <ul>
                <li>
                    − Podmiotom przetwarzającym dane osobowe w naszym imieniu Aplikacji w tym dostawcom usług
                    IT, usług marketingowych (w tym spoza Europejskiego Obszaru Gospodarczego), dostawcom
                    hostingu oraz usług w zakresie organizacji wydarzeń, oraz
                </li>
                <li>− operatorowi Aplikacji i operatorom wysyłki wiadomości e-mail i SMS.</li>
            </ul>
            <p>
                Takie podmioty przetwarzają dane na podstawie umowy z nami i tylko zgodnie z naszymi
                poleceniami.
            </p>
            <h3>6. Przekazywanie danych poza Europejski Obszar Gospodarczy</h3>
            <p>
                Twoje dane osobowe będą przekazywane do Stanów Zjednoczonych do zewnętrznych dostawców usług
                IT oraz dostawców usług marketingowych. Na obecną chwilę Stany Zjednoczone nie gwarantują
                odpowiedniego poziomu ochrony danych. Organy państwowe mogą mieć dostęp do tych danych ze
                względu na przepisy prawa zezwalające na inwigilację.
            </p>
            <p>
                Niektórzy z dostawców usług, z których korzystamy, przyjęli standardowe klauzule ochrony
                danych i pomimo istnienia przepisów prawa, o których mowa powyżej, starają się zapewnić
                możliwie jak najwyższy poziom ochrony danych.
            </p>
            <h3>7. Twoje prawa związane z przetwarzaniem danych osobowych</h3>
            <p>Masz następujące prawa związane z przetwarzaniem danych osobowych:</p>
            <ul>
                <li>
                    h. prawo do <strong>wycofania zgody</strong>,
                </li>
                <li>
                    i. prawo <strong>wyrażenia sprzeciwu</strong> wobec przetwarzania Twoich danych ze względu
                    na Twoją szczególną sytuację – w przypadkach, kiedy przetwarzamy Twoje dane na podstawie
                    naszego prawnie uzasadnionego interesu,
                </li>
                <li>
                    j. prawo <strong>dostępu</strong> do Twoich danych osobowych,
                </li>
                <li>
                    k. prawo <strong>żądania sprostowania</strong> Twoich danych osobowych,
                </li>
                <li>
                    l. prawo <strong>żądania usunięcia</strong> Twoich danych osobowych,
                </li>
                <li>
                    m. prawo <strong>żądania ograniczenia</strong> przetwarzania Twoich danych osobowych,
                </li>
                <li>
                    n. prawo do <strong>przenoszenia</strong> Twoich danych osobowych, tj. prawo otrzymania od
                    nas Twoich danych osobowych, w ustrukturyzowanym, powszechnie używanym formacie
                    informatycznym nadającym się do odczytu maszynowego. Możesz przesłać te dane innemu
                    administratorowi danych lub zażądać, abyśmy przesłali Twoje dane do innego administratora.
                    Jednakże zrobimy to tylko jeśli takie przesłanie jest technicznie możliwe.
                </li>
            </ul>
            <p>
                Aby skorzystać z powyższych praw, skontaktuj się z nami (dane kontaktowe w punkcie 1 powyżej).
            </p>
            <p>
                <u>Prawo wycofania zgody</u>
            </p>
            <p>
                W zakresie, w jakim Twoje dane są przetwarzane na podstawie zgody, masz prawo wycofania zgody
                na przetwarzanie danych w dowolnym momencie. Wycofanie zgody nie ma wpływu na zgodność z
                prawem przetwarzania, którego dokonano na podstawie Twojej zgody przed jej wycofaniem.
            </p>
            <p>
                Zgodę możesz wycofać poprzez wysłanie oświadczenia na adres email{' '}
                <a href="mailto:dane@qualityappstudio.pl">dane@qualityappstudio.pl</a>
            </p>
            <p>
                <u>Prawo wyrażenia sprzeciwu</u>
            </p>
            <p>
                W związku z tym, że Twoje dane są przetwarzane na podstawie naszego prawnie uzasadnionego
                interesu (art. 6 ust. 1 lit. f) RODO) – w celu marketingu bezpośredniego masz prawo zgłoszenia
                sprzeciwu wobec przetwarzania Twoich danych osobowych przez Quality App Studio Sp. z o.o. w
                dowolnym momencie. Masz także prawo zgłoszenia sprzeciwu wobec przetwarzania Twoich danych
                osobowych przez Quality App Studio Sp. z o.o. ze względu na Twoją szczególną sytuację.
            </p>
            <p>
                Sprzeciw możesz zgłosić poprzez wysłanie oświadczenia na adres email{' '}
                <a href="dane@qualityappstudio.pl">dane@qualityappstudio.pl</a>
            </p>
            <p>
                <u>Prawo wniesienia skargi do organu</u>
            </p>
            <p>
                Przysługuje Ci także prawo wniesienia skargi do organu nadzorczego zajmującego się ochroną
                danych osobowych, tj. Prezesa Urzędu Ochrony Danych Osobowych.
            </p>
            <h3>9. Wymóg podania danych</h3>
            <p>
                Podanie danych zawartych w formularzu rejestracji jest niezbędne, aby zarejestrować się do
                Aplikacji i móc z niej korzystać
            </p>
        </StyledPrivacyContainer>
    </Container>
);

export { Privacy };
